/* eslint-disable no-undef */
// import RevolutCheckout from '../prettier.config';
import './css/style.css';

let invoiceNumberGlob = '';

const host = document.location.hostname === 'pay.easproject.com' ? '' : 'https://pay-dev.easproject.com';
const prodOrSandbox = document.location.hostname === 'pay.easproject.com' ? 'prod' : 'sandbox'
var saveCardAfterPay = false;
const revolutCO = (publicId) =>
	RevolutCheckout(publicId, prodOrSandbox).then(function (instance) {
		var payButton = document.getElementById('pay-button');
		// On click open payment pop-up
		payButton.addEventListener('click', function () {
			instance.payWithPopup({
				onSuccess() {
					console.log('Payment completed');
					checkInvoice();
				},
				onError(error) {
					console.error('Payment failed: ' + error.message);
					location.reload();
				},
				onCancel() {},
				savePaymentMethodFor: saveCardAfterPay ? 'merchant' : null,
			});
		});
		instance.revolutPay({
			target: document.getElementById('revolut-button'),
			onSuccess() {
				console.log('Payment completed');
				checkInvoice();
			},
			onError(error) {
				console.error('Payment failed: ' + error.message);
			},
		});

		const paymentRequest = instance.paymentRequest({
			target: document.getElementById('revolut-applepay-button'),
			requestShipping: false,
			onSuccess() {
				// console.log('Payment completed');
				// location.reload();
				checkInvoice();
			},
			onError(error) {
				console.error('Payment failed: ' + error.message);
			},
		});

		paymentRequest.canMakePayment().then((method) => {
			if (method) {
				// console.log('method', method);
				if (method === 'googlePay')
					document.getElementById('apple-pay-button').innerHTML = 'Google Pay';
				paymentRequest.render();
			} else {
				// console.log('error render');
				paymentRequest.destroy();
			}
		});
	});

const params = window.location.search
	.replace('?', '')
	.split('&')
	.reduce(function (p, e) {
		var a = e.split('=');
		p[a[0]] = a[1];
		return p;
	}, {});

// console.log(params['invoiceId']);

const paidFoo = (invoiceNumber) => {
	setTimeout(() => {
		document.getElementById('invoice-paid-number').innerHTML = invoiceNumber;
		document.getElementById('spinner').hidden = true;
		document.getElementById('spinner').style.display = 'none';
		document.getElementById('pay-form').hidden = true;
		document.getElementById('error').hidden = true;
		document.getElementById('paid').hidden = false;
		document.getElementById('footer').hidden = false;
	}, 2000);
};
const errorFoo = () => {
	setTimeout(() => {
		// console.log('err');
		document.getElementById('spinner').hidden = true;
		document.getElementById('spinner').style.display = 'none';
		document.getElementById('pay-form').hidden = true;
		document.getElementById('paid').hidden = true;
		document.getElementById('error').hidden = false;
		document.getElementById('footer').hidden = false;
	}, 2000);
};

const noPaidFoo = (commits) => {
	document.getElementById('pay-form').hidden = false;
	document.getElementById('paid').hidden = true;
	document.getElementById('error').hidden = true;
	document.getElementById('footer').hidden = false;

	document.getElementById('total-order-amount').innerHTML = commits.totalOrderAmount;
	document.getElementById('invoice-number').innerHTML = commits.invoiceNumber;
	document.getElementById('invoice-amount').innerHTML = commits.invoiceAmount;
	document.getElementById('paymant-fee').innerHTML = commits.fee;
	document.getElementById('company-name').innerHTML = commits.companyId == 1? 'Easy Access System Europe OÜ' : 'Easy Access System Project OÜ';
	document.getElementById('save-card-button').hidden = commits.companyId == 1;
};
const noSpinnerFoo = () => {
	document.getElementById('spinner').hidden = true;
	document.getElementById('spinner').style.display = 'none';
};

const spinnerFoo = () => {
	document.getElementById('spinner').hidden = false;
	document.getElementById('spinner').style.display = 'flex';
	document.getElementById('pay-form').hidden = true;
	document.getElementById('paid').hidden = true;
	document.getElementById('error').hidden = true;
	document.getElementById('footer').hidden = true;
};
const checkFalseFoo = () => {
	console.log('Check false');
	document.getElementById('spinner').hidden = true;
	document.getElementById('spinner').style.display = 'none';
	document.getElementById('pay-form').hidden = true;
	document.getElementById('paid').hidden = true;
	document.getElementById('error').hidden = true;
	document.getElementById('check-false').hidden = false;
	document.getElementById('footer').hidden = false;
	setTimeout(() => {
		location.reload();
	}, 5000);
};

debugger;
fetch(`${host}/api/invoice/${params['invoiceId']}`)
	.then((response) => response.json())
	.then((commits) => {
		invoiceNumberGlob = commits.invoiceNumber;
		if (commits.paymentStatus === 'paid') {
			paidFoo(commits.invoiceNumber);
			return null;
		} else if (commits.paymentStatus === 'pending');
		else {
			if (commits.message)
				document.getElementById(
					'error',
				).innerHTML = `<span style="color: #000000">ERROR: ${commits.message}</span>`;
			console.log(commits);
			errorFoo();
			console.log('errorFoo');
			return null;
		}
		console.log('promise', commits.paymentStatus);
		return new Promise((resolve) => {
			// noSpinnerFoo();
			resolve(commits);
		});
	})
	.then((resp) => {
		if (resp) {
			revolutCO(resp.revolutPublicId);
			setTimeout(() => {
				noPaidFoo(resp);
				noSpinnerFoo();
			}, 2000);
		}
	})
	.catch(() => errorFoo());

const checkInvoice = () => {
	spinnerFoo();
	fetch(`${host}/api/invoice/${params['invoiceId']}/check`)
		.then((response) => response.json())
		.then((commits) => {
			if (commits.status) paidFoo(invoiceNumberGlob);
			else checkFalseFoo();
		})
		.catch(() => errorFoo());
};

const saveCardCheckbox = document.getElementById('save-card');
saveCardCheckbox.onclick = () => {
	saveCardAfterPay = saveCardCheckbox.checked;
	console.log('set savecard to '+ saveCardAfterPay);
	fetch(
		`${host}/api/invoice/${params['invoiceId']}/saveCardAfterPay?status=${saveCardCheckbox.checked}`,
		{ method: 'POST', headers: { 'Content-Type': 'application/json' } },
	);
};
